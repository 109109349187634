import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './stories.css'




const Introduction = () => {

  const navigate = useNavigate();

  // const navigate = useNavigate();
  // const goBackToPostShablon = () => {
  //   navigate(-1);
  // };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [visibleBubbles, setVisibleBubbles] = useState({});

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    const initialVisibility = {};
    const numberOfCases = 4;
    for (let i = 1; i <= numberOfCases; i++) {
      initialVisibility[i] = window.innerWidth > 970;
      // console.log(initialVisibility[i])
    }
    setVisibleBubbles(initialVisibility);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleSupClick = (index) => {
    setVisibleBubbles(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };


  return (
    <div className="minister-home stories">
      <div className="separate_into_two_columns">
        <main className="sep_part1">
          <div className='bism'><p>@</p></div>

          <article className='flex_in_doublecolumn'>
            <div className='uzbek_quron_doublecolumn'>
              <p>«Эй барча мулкнинг эгаси Раббим! Хоҳлаган кишингга мулк берурсан ва хоҳлаган кишингдан мулкни тортиб олурсан, хоҳлаган кишингни азиз қилурсан, хоҳлаган ки­шингни хор қилурсан. Барча яхшилик Сенинг қўлингда. Албатта, Сен ҳар бир нарсага қо­дир­сан. Кечани кун­дуз­га ки­ри­тур­сан ва кундузни ке­ча­га киритурсан, ўлик­дан тирикни чи­қа­рур­сан ва тирикдан ўликни чиқарурсан ҳамда хоҳ­ла­ган ки­­шинг­­га бе­ҳи­соб ризқ берурсан» <span className='vitalic'>(Оли Имрон 3:26-27).</span> </p>
            </div>

            <div className='arabic_quran_doublecolumn'>
              <p>
                ٱللَّهُمَّ مَـٰلِكَ ٱلْمُلْكِ تُؤْتِى ٱلْمُلْكَ مَن تَشَآءُ وَتَنزِعُ ٱلْمُلْكَ مِمَّن تَشَآءُ وَتُعِزُّ مَن تَشَآءُ وَتُذِلُّ مَن تَشَآءُ ۖ بِيَدِكَ ٱلْخَيْرُ ۖ إِنَّكَ عَلَىٰ كُلِّ شَىْءٍۢ قَدِيرٌۭ تُولِجُ ٱلَّيْلَ فِى ٱلنَّهَارِ وَتُولِجُ ٱلنَّهَارَ فِى ٱلَّيْلِ ۖ وَتُخْرِجُ ٱلْحَىَّ مِنَ ٱلْمَيِّتِ وَتُخْرِجُ ٱلْمَيِّتَ مِنَ ٱلْحَىِّ ۖ وَتَرْزُقُ مَن تَشَآءُ بِغَيْرِ حِسَابٍۢ
              </p>
            </div>
          </article>

          <div className='paragraph_heading'>
            <p style={{ fontSize: '18px', margin: '15px' }}> Муқаддима </p>
          </div>

          <div className='uzbek_quron'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[1] ? 'block' : 'none' }}
              onClick={() => handleSupClick(1)}
            >
              <p>
                <sup>1</sup> Абу Зар Ғифорий шундай дейди: «Ё Расулaллоҳ, Мусонинг саҳифаларида нималар бор эди?» деб сўрадим. Пайғамбаримиз алайҳиссалом: «Ҳаммаси ибрат эди» дедилар. </p>
              <p className='who_wrote'>(Ибн-и Сод. “Табақот”, 1-жилд, 155- бет.)</p>
            </div>

            <p className='boldItalic'
              onClick={() => handleSupClick(1)}
            > «Батаҳқиқ, <span className='vitalic'>(пайғамбарлар)</span> қиссаларида ақл эгалари учун ибрат бор».
              <span className='vitalic'> (Юсуф сураси, 111-оят)</span><sup>1</sup>
            </p>

          </div>

          <div className='main_text'>


            <p>
              Aлҳамду лиллаҳи раббил аъламийн. Вас-солату вас-саламу аъла саййидина Муҳаммад ва аъла алиҳи ва асҳобиҳи ажмаъийн. Ассалому алайкум ва раҳматуллоҳи ва баракотуҳу. Аъмма баъд...
              Вас-солату вас-саламу аъла саййидина Муҳаммад ва аъла алиҳи ва асҳобиҳи ажмаъийн. Ассалому алайкум ва раҳматуллоҳи ва баракотуҳу. Аъмма баъд...
            </p>

            <p>
              Ушбу китоб араб тилида “Қисас ал-Анбия” деб аталиб, пайғамбарлар (а.с.) тарихини ҳикоя қилади. Бу жанрда ёзилган китоблар бир нечта. Улардан биринчиси Муҳаммад Ибн Исҳоқнинг тўплами бўлиб, у киши ўз асарини асосан уч мавзуга бағишлаган: дунёнинг яратилиши ҳамда Муҳаммад (с.а.в.) дан аввалги пайғамбарлар тарихи (Kitab al-mubtada’), пайғамбаримиз  (с.а.в.) нинг ҳаёти ва олган ваҳийлари (Сира), ва у кишининг ҳарбий юришлари (Maghozi).
            </p>
            <p>Афсуски, Ибн Исҳоқнинг асари бизгача етиб келмади, лекин баъзи уламолар, масалан Ал-Табарий ўз тафсирида ва олам тарихи ҳақида ёзган китобида Ибн Исҳоқнинг гапларини қайта-қайта тилга олган (қаранг: Tarikh al-rusul wa-l-muluk). Хусусан, сўнгги китобнинг ғоясида ҳам, шаклида ҳам Ибн Исҳоқнинг таъсири жуда кучли.
            </p>
            <p>
              Қисас ал-Анбия жанрида қалам тебратган ёзувчилардан яна бири аслан келиб чиқиши форсий бўлмиш яманлик Ваҳб ибн Мунаббиҳ. У киши пайғамбарлар тарихи ҳақида баён қилувчи юзта бўлимни ўз ичига олган тўплам соҳиби. Араб тилидан ташқари, иброний ҳамда арамий тилларини ҳам яхши билган. Таврот ва Забур китобларини асл матнда ўқий олар, шу сабабдан қадимги пайғамбарлар тарихини кучли далил ва ҳужжатлар билан баён этган.
            </p>
            <p></p>
          </div>
          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[2] ? 'block' : 'none' }}
              onClick={() => handleSupClick(2)}
            >
              <p> <sup>2</sup>                 Бу қўлёзмада дунёнинг яратилиши, Иброҳим (а.с.) ва у кишидан аввал умр сурган пайғамбарлар тарихи баён этилган.
              </p>
            </div>
            <p onClick={() => handleSupClick(2)}
            >
              Қисас ал-Анбиянинг яна бир тўплами хуросонлик Исҳоқ ибн Бишрга тегишли.
              У киши бошқа мавзуларда ҳам асарлар яратган, пайғамбарлар тарихига атаб эса «Mubtada’ al-dunyа wa qisas al-Anbiyа», деган китоб ёзган (яъни, «Дунёнинг бошланиши ва пайғамбарлар тарихи»). Яқин-яқингача бу китоб йўқолган саналар, бахтга кўра бир неча йил олдин китобнинг биринчи қисми топилиб, дунё кутубхонаси яна битта қўлёзмага бойиди.<sup>2</sup>
            </p>
            <p></p>
          </div>
          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[3] ? 'block' : 'none' }}
              onClick={() => handleSupClick(3)}
            >
              <p> <sup>3</sup> Абу Исҳоқ пайғамбаримиз Муҳаммад (с.а.в.) дан 400 йил кейин яшаган таҳлилчи олим эди.</p>
            </div>
            <p onClick={() => handleSupClick(3)}
            >
              Пайғамбарлар тарихининг кейинги тўплами Абу Исҳоқ ал-Салабийники.<sup>3</sup>          Унинг илоҳий битикларга ёзган тафсирлари катта илмий аҳамиятга эга бўлса-да, халқ орасида унчалик шуҳрат қозонмади.
            </p>

            <p>
              Навбатдаги ёзувчи Ал-Ҳайсам ибн Муҳаммад ал-Бушанжий. Унинг араб тилида битилган «Qisas al-Qur’аn» тўплами ҳам алоҳида диққатга сазовор. Китоб Қуръонда айтилган пайғамбарлар тарихини, пайғамбарлар ҳақида айтилган ривоятларни, ҳамда набий Муҳаммад (с.а.в.) нинг ҳаётини баён қилади.
            </p>
            <p>
              Қуддуслик Аҳмад ибн Абу Узайба ҳам «Қисас ал-Анбия» тўплаган, лекин бизгача унинг фақат битта қўлёзмаси етиб келган, холос.
            </p>
            <p>
              Араб бўлмаган муаллифлар ичида эса Насриддин Рабғузийнинг туркий тилда ёзган китоби энг муҳим ўрин эгалласа керак. Унинг пайғамбарлар ҳақида ёзган қиссалари катта ҳажмда бўлиб, кўп тафсилотларни ўз ичига олган ривоят ва афсоналардан иборат.
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[4] ? 'block' : 'none' }}
              onClick={() => handleSupClick(4)}
            >
              <p>
                <sup>4</sup> Айтиш лозимки, мана шу охирги юз йил мобайнида Ибн Касирнинг тўплами оммага кенг таъсир ўтказди.
              </p>
            </div>
            <p onClick={() => handleSupClick(4)}>
              Кейинроқ Ибн Таймийя ҳамда унинг шогирди Ибн Касир, ўша замонда мавжуд бўлган Қисас ал-Анбия китобларини текширувдан ўтказадилар. Баъзи ҳикояларни, айниқса яҳудий ҳамда насроний матнларда мавжуд бўлган қиссаларни рад этадилар.<sup>4</sup>
            </p>

            <p>
              Ҳа, асрлар оша авлоддан авлодга ўтиб келаётган бу адабий жанр, «Қисас ал-Анбия» китоблари, ҳануз ўқувчилар орасида машҳур. Араб, форс ҳамда туркий халқлар орасида «Пайғамбарлар тарихи» қайта-қайта нашр қилинмоқда. Масалан, мисрлик ал-Шаровийнинг “Қисас ал-Анбия” си ҳам жуда машҳур. 3000 саҳифадан ортиқ бу китоб беш томдан иборат бўлиб, пайғамбарлар тарихини замонавий тарзда шарҳлаган, юзаки бўлса ҳам, жуда кўп маълумотни ўз ичига олган.
            </p>

            <p> Шу пайтгача араб тилида, форс тилида ҳамда туркий тил­ларда пай­ғам­барлар тарихи кўп марта нашр бўлган. Лекин афсуски, ўзбек тилида бу адабий жанр жуда кам бо­силди. Шу сабабдан биз ҳам, айтишга арзигулик бўлмаса-да, бу борада бироз тер тўкиб, ўтган пайғамбарлар та­рихи ҳақида озгина бўлса ҳам маълумот етказишга ҳаракат қилдик.
            </p>

            <p>
              Ушбу китоб Абу Ҳамид ибн Муҳаммад­нинг тўплами. У киши пайғамбарлар тарихини қадимий ишончли манбалардан, хусусан Таврот ва Забури шарифлардан ўрганган, Қуръони каримда бу ҳақда ёзилган оятларни таҳлил қилган ва Ислом динидаги буюк уламолар, жумладан Табарий, Ибн Исҳоқ, Ибн Халдун ҳамда Яъқубий каби олимларнинг тафсирларини ҳисобга олган. Биз бу тўпламни баҳоли-қудрат ўзбек тилига таржима қилдик. Умид қиламизки, пай­ғам­барлар қиссаси қалбингизга ил­ҳом бе­риб, ях­ши­лик­ка, тўғ­риликка, тақволи ҳаётга даъват этса.
            </p>

            <p>
              Ҳурмат билан, таржимонлар гуруҳи.
            </p>
            <p></p>
          </div>


          <article className='nav_arrows'>
            <div className='flex_it space_between'>
              <div style={{ marginLeft: '0px' }} onClick={() => navigate(-1)}>
                <div>
                  <span className='left_arrow_span'>&#8592;</span>
                </div>
                <div className='indicator'>
                  <span className=' bottom_arrows_for_qisas' > Кириш </span>
                </div>
              </div>

              <div className='central_div'>
                <div>
                  <p>Muqaddima</p>
                </div>
              </div>

              <div style={{ marginRight: '0px' }} onClick={() => navigate('/qisas-uzbek/adam')}>
                <div className='indicator'>
                  <span className=' bottom_arrows_for_qisas' >  Одам (а.с.) қиссаси: </span>
                </div>
                <div>
                  <span className='right_arrow_span' >&#8594;</span>
                </div>
              </div>
            </div>
          </article>
          <footer>
            <div>
              <p> © 2016 Abdul-Ghafur ibn Abdul-Sattar </p>
            </div>
          </footer>
         
        </main>
        <aside> </aside>
      </div>
    </div >
  )
}

export default Introduction