import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './stories.css'


const ShariahLaws = () => {

  const navigate = useNavigate();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [visibleBubbles, setVisibleBubbles] = useState({});

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    const initialVisibility = {};
    const numberOfCases = 14;

    for (let i = 1; i <= numberOfCases; i++) {
      initialVisibility[i] = window.innerWidth > 970;
      //     console.log(initialVisibility[i])
    }
    setVisibleBubbles(initialVisibility);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleSupClick = (index) => {
    setVisibleBubbles(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };


  return (

    <div className="minister-home stories">
      <div className="separate_into_two_columns">
        <main className="sep_part1">
          <div className='chapter_heading2'>
            <p> Шариат аҳкомидан   баъзи  </p>
            <p>мисоллар</p>
          </div>

          <div className='decor_line'>
            <p>
              &#8213;&#8213;  &#10044; &#8213;  &#10044;  &#8213;  &#10044;  &#8213;&#8213;
            </p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[1] ? 'block' : 'none' }}
              onClick={() => handleSupClick(1)}
            >
              <p >
                <sup>1</sup> Таврот шариатида тасодифан одам ўлдирганлар майит эгаларининг адоватидан сақланиш учун махсус шаҳарларга бориб яшаши кўзда тутилган.
              </p>
            </div>
            <p onClick={() => handleSupClick(1)}>
              Кимдир бировни ўлдириш учун урса ва ўлдирса, унинг ўзини ўлимга ҳукм қилинглар. Лекин агар тасодифан ўлдирган бўлса, ўлимга ҳукм қилманглар.<sup>1</sup>
            </p>
            <p>
              Отасини ёки онасини урган ё ҳақорат қилган одамни ўлим жазосига ҳукм қилинглар.
            </p>
            <p>
              Ким одам ўғирласа, сотса ҳам, сотмаса ҳам, ўлим жазосига лойиқ.
            </p>
            <p>
              Жанжал чиққанда, кимдир бировни тош ё мушт билан уриб жароҳатласаю, у бир муддат тўшакда ётиб, кейин туриб кетса, урган кишини жазога тортманглар. Фақат у энди, жароҳат олган киши соғайгунича, ишсиз қолган кунларининг товонини ҳамда муолажасининг харажатини тўлаши керак.
            </p>
            <p>
              Одамлар урушаётган вақтда, ўртада ҳомила хотин зарб еб, ҳомиласи бевақт туғилса, лекин она билан болага жиддий зарар етмаса, урган киши хотиннинг эрига у истаган ва қози тасдиқлаган миқдорда жарима берсин.
            </p>
            <p>

              Аммо бирон зарар етса, жон ўрнига жон, кўз ўрнига кўз, тиш ўрнига тиш, қўл ўрнига қўл, оёқ ўрнига оёқ, куйган ўрнига куйган, кўкарган ўрнига кўкарган, яра ўрнига яра жазоси берилсин.
            </p>
            <p className="vitalic">
              Ушбу қонунни тушуниш учун қуйидагича бир вазиятни тасаввур қилайлик. Айтайлик ўғлингиз бирорта бойваччани ёки зўравоннинг боласини уриб бир кўзини кўр қилиб қўйди. Нима деб ўйлайсиз, энди унинг отаси ўғлингизни нима қилади? Балки ўлдираман, дер. Балки иккала кўзини ўйиб оламан, дер. Юқоридаги қонун, бериладиган жазонинг ҳадди-чегарасини белгилайди. Битта кўз учун битта кўзга жазо берилади. Битта тиши синган бўлса, жазо сифатида фақат битта тиши синдирилади (“Ҳамма тишини қоқиб оламан” дейдиганлар ҳам топилади-да). Лекин кечириб юборса, афзалроқ.
            </p>
            <p>
              Агар кимнингдир ҳўкизи бирор эркак ё аёлни шохлаб ўлдирса, ҳўкизни тошбўрон қилинглар. Унинг гўштини ейиш мумкин эмас. Эгасини айбдор қилманглар. Лекин агар одамлар унга ҳўкизинг шохлайди, деб огоҳлантирган бўлса-ю, ҳўкизини яхши боғламаган бўлса, ҳам ҳўкиз тошбўрон қилинсин, ҳам эгаси ўлдирилсин. Фақат ҳўкиз эгасидан хунбаҳо талаб қилинган ҳолатда, айтилган пулни бериб, ўзини ўлимдан қутқариши мумкин. Ҳўкиз ёш болани шохлаб ўлдирса ҳам, айни ҳукм ижро этилсин.
            </p>
            <p>
              Агар бир киши чуқур қаздириб, устини ёпмаса ва чуқурга молми-эшакми тушиб ўлса, чуқур қаздирган одам ҳайвоннинг бадалини тўласин. Ўлган ҳайвон эса чуқур эгасига қолади.
            </p>
            <p>
              Агар кимнингдир ҳўкизи бошқа бировнинг ҳўкизини шохлаб ўлдирса, тирик қолган ҳўкизни сотиб, пулини бўлиб олсинлар, ўлган ҳайвонни ҳам иккаласи бўлиб олади. Лекин агар у ҳўкизининг сузонғич эканини била туриб, қарамаган бўлса, ўлган ҳўкизнинг бадалига ҳўкиз берсин. Ўлган ҳўкиз эса ўзига қолади.
            </p>
            <p>
              Ким ҳўкиз ё қўй ўғирлаб сотса, ё сўйса, бир ҳўкиз ўрнига беш ҳўкиз, бир қўй ўрнига тўрт қўй берсин.
            </p>
            <p>
              Ўғирланган ҳайвон саломат қўлга тушса, икки баравар товон тўла­син. Товонини беролмаса, товон учун ўғрининг ўзини қулликка сотинглар.
            </p>
            <p>
              Кечаси ўғирлик қилиш учун бировнинг уйига кираётган ўғри уриб ўлдирилса, ўлдирган киши гуноҳкор бўлмайди, аммо агар бу воқеа кундузи содир бўлса, ўлдирган киши қотил саналади.
            </p>
            <p>
              Агар кимнингдир моли бошқа бировнинг экини ё боғига кириб зарар етказса, мол эгаси ўз экинзор ё боғининг энг яхши маҳсулотидан товон берсин.
            </p>
            <p>
              Кимдир олов ёқсаю, бошқа бировнинг ҳосили ё экини ёниб кетса, олов ёққан киши товонини тўлайди.
            </p>
            <p>
              Бировга пул ё нарса омонат берсангизу, ўғри ўғирлаб кетса, ўғри то­пилгач икки баравар қилиб тўласин. Ўғри топилмаса, уй эгаси: “Мен омонатга хиёнат қилмадим”, деб Худонинг олдида қасам ичсин.
            </p>
            <p>
              Агар ҳўкиз ёки эшак ёки қўйингизни, ё бошқа биронта ҳайвонни бировга омонат берсангизу ҳайвон ўлса ё шикастланса ё тортиб олинса, лекин ўртада гувоҳ бўлмаса, омонат олган одам: “Мен молингга хиёнат қилмадим”, деб Оллоҳнинг олдида қасам ичсин. Мол эгаси унинг қасамини қабул қилсин, мол учун товон олмасин. Лекин агар мол ўғирланган бўлса, товон тўласин. Ваҳший ҳайвон еган бўлса, жасадини кўрсатсин. Бу ҳолатда ҳам товон тўланмайди.
            </p>
            <p>
              Агар бировнинг ҳайвонини сўраб олсангизу у жароҳатланса ё нобуд бўлса, ҳайвон учун товон тўлайсиз. Лекин агар жароҳат олган пайтда ҳайвон эгаси ўша ерда бўлса, товон тўланмайди. Агар ҳайвонни ижарага олган бўлсангиз, ижара пулини берасиз холос.
            </p>
            <p>
              Кимдир агар унаштирилмаган қизнинг кўнглини олиб, уни беиффат қилса, қиз учун қалин тўлаб уйлансин. Агар қизнинг отаси қизини беришга рози бўлмаса, бокиралар учун бериладиган қалин пулини товон сифатида тўласин.
            </p>
            <p>
              Сеҳр-жоду билан шуғулланадиган одамларнинг жазоси ўлим.
            </p>
            <p>
              Ҳайвон билан қўшилган одам ўлимга лойиқ.
            </p>
            <p>
              Мусофирларга ёмон муомала қилманглар, зулм ўтказманглар. Чунки сизлар ҳам Мисрда мусофир эдингиз.
            </p>
            <p>
              Бева аёллар ва етимларни хорламанг. Хорласангиз, улар Менга фарёд қилади – Мен уларнинг фарёдига қулоқ тутаман, қаҳрланаман. Хотинларингиз бева, болаларингиз етим қолади. Шундай экан, уларга озор берманглар.
            </p>
            <p>
              Камбағал биродарлар учун қўлингиз очиқ бўлсин, бағ­ритош бўлманг. Қарз сўраб келса, муҳтожлигини қоплашга етарли қилиб беринг. Қарз устига фоиз қўйманг.
            </p>
            <p>
              Қарз бераётганда пулингизга ачинманг, чунки Оллоҳ таоло ишларингизга барака беради.
            </p>
            <p>
              Бировнинг ёпинчиғини гаровга олсангиз, кун ботмай туриб қайтаринг. Бу унинг ягона ёпадиган нарсаси. Усиз нимага ўраниб ухлайди? Менга фарёд қилганда унинг додига қулоқ соламан, чунки Мен раҳм-шафқат Худосиман.
            </p>
            <p>
              Худога қарши ёмон гаплар айтманг. Халққа раҳбарлик қилаётганларни сўкманг.
            </p>
            <p>
              Ёлғон гаплар тарқатманг. Ёлғон гувоҳлик бериб нотўғри иш қилган одамларга ёрдам берманглар.
            </p>
            <p>
              Хато ишга, ҳатто кўпчилик қўл урган ҳолда ҳам шерик бўлма. Даъво пайтида кўпчиликка эргашиб, адолатни оёқости қилма. Ҳукм қилиш пайтида ҳатто фақирга ҳам фақир деб, ён босма, камбағал деб, кимнингдир ҳақ-ҳуқуқини поймол қилма.
            </p>
            <p>
              Душманингнинг йўқолган ҳўкизи ё эшагига дуч келсанг, эгасига олиб бориб бер.
            </p>
            <p>
              Сени ёмон кўрадиган одамнинг эшаги юк остида йиқилиб ётганини кўрсанг, ўз ҳолига ташлаб кетма, эшагини турғазишда унга ёрдам бер.
            </p>
            <p>
              Ёлғон даъволарда иштирок этманг, айбсиз одамларни ўлимга ҳукм қилишда қўлингиз бўлмасин. Бундай иш қилган одамни Мен жазосиз қолдирмайман.
            </p>
            <p>
              Пора олманглар. Чунки пора бийнони нобийнога, ҳақни ноҳаққа айлантиради. <span className="vitalic">(Яъни, кўзи кўриб турган одамни кўрмайдиган қилиб қўяди.)</span>
            </p>
            <p>
              Мусофирларни хафа қилманглар. Мусофир бўлиш нима эканини яхши биласизлар. Ўзларингиз ҳам Мисрда мусофир бўлган­сиз­лар.  Ҳукм пайтида, мусофирлар учун ҳам, ўзларингиз учун ҳам бир хил иш тутинг.
            </p>
            <p>
              Биронта одамни ўлимга ҳукм қилсангиз камида икки ёки уч одам гувоҳлик берсин. Бир гувоҳнинг гапи билан иш тутманглар.

              Биринчи бўлиб гувоҳлар тош отсин, кейин бутун жамоа тошбўрон қилади. Шу йўл билан орангиздан разолатни йўқ қиласиз.
            </p>
            <p>
              Ерингизни олти йил экинг, ҳосил олинг. Еттинчи йили эса ерга дам беринглар. Дам олиш йилида ерни ишламанг, йўқсиллар ўша йили ўсиб чиққан экинлардан фойдалансин. Ортгани эса ёввойи ҳайвонларга қолади. Узум ва зайтун боғларини ҳам шундай қилинглар.
            </p>
            <p>
              Ҳафтада олти кун ишланг, еттинчи куни дам олинг. Ҳўкизу эшагингиз ҳам, хизматкорларингиз ҳам, уйингиздаги бегона халқ вакиллари ҳам дам олиб ҳордиқ чиқарсин.
            </p>
            <p>
              Менинг малагим сизларни амўрийлар, ҳитийлар, перизийлар, канъонийлар, ҳавийлар ва ябусийлар юртига олиб боради. Юртни эгаллар экансиз, уларнинг худоларига сажда қилманг, хизмат этманг, расмларини бажарманг. Аксинча, бутларини парчалаб, ўрнатган тошларини таг-тубидан синдиринг. Чунки сизлар Меникисизлар! Менга ибодат қилсангиз, ризқу насибангизга барака қўшаман, ҳар қандай касалликлардан сақлайман».
            </p>
            <p></p>
          </div>






          <article className='nav_arrows'>
            <div className='flex_it space_between'>
              <div style={{ marginLeft: '0px' }} onClick={() => navigate(-1)}>
                <div>
                  <span className='left_arrow_span'>&#8592;</span>
                </div>
                <div className='indicator'>
                  <span className=' bottom_arrows_for_qisas' > орқага қайтиш </span>
                </div>
              </div>

              <div className='central_div'>
                <div>
                  <p>Шариатдан намуналар</p>
                </div>
              </div>

              <div style={{ marginRight: '0px' }} onClick={() => navigate('/qisas-uzbek/bani-israel-accepted-shariah')}>
                <div className='indicator'>
                  <span className=' bottom_arrows_for_qisas' > Аҳдлашиш</span>
                </div>
                <div>
                  <span className='right_arrow_span' >&#8594;</span>
                </div>
              </div>
            </div>

          </article>
         
        </main >
        <aside> </aside>
      </div >
    </div >
  )
}

export default ShariahLaws
