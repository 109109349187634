import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './stories.css'


const EXAMPLE = () => {

  const navigate = useNavigate();

  // const [search, setSearch] = useState('')


  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [visibleBubbles, setVisibleBubbles] = useState({});

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    const initialVisibility = {};
    const numberOfCases = 7;

    for (let i = 1; i <= numberOfCases; i++) {
      initialVisibility[i] = window.innerWidth > 970;
      //     console.log(initialVisibility[i])
    }
    setVisibleBubbles(initialVisibility);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleSupClick = (index) => {
    setVisibleBubbles(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };


  return (

    <div className="minister-home stories">
      <div className="separate_into_two_columns">
        <main className="sep_part1">
          <div className='chapter_heading2'>
            <p> Яъқуб (а.с.) нинг Канъонга  </p>
            <p>қайтиши</p>
          </div>

          <div className='decor_line'>
            <p> &#8213;&#8213;  &#10044; &#8213;  &#10044;  &#8213;  &#10044;  &#8213;&#8213;   </p>
          </div>

          <div className='main_text'>
            <p>
              Юсуф (а.с.) туғилгандан кейин ҳазрати Яъқуб қайнотасига деди: «Энди менга рухсат беринг, отам юртига қайтсам. Хотин-бола-чақаларимни ҳам олиб кетаман. Улар учун сизга қанча хизмат қилганимни биласиз-ку!»
            </p>
          </div>

          <div className='paragraph_heading'>
            <p>
              ЛОБОН БИЛАН ЯЪҚУБ (А.С.) НИНГ АҲДИ
            </p>
          </div>

          <div className='main_text'>
            <p>
              —Кетма, — деди Лобон. — Оллоҳ мени сен сабабли баракалади, мана, бойиб кетдим. Қолсанг яхши бўлар эди. Айт, иш ҳаққингга қанча тўлаш керак бўлса тўлайман.
            </p>

            <p>
              —Сизга қандай хизмат қилганимни, қўй-сигирларингизни қандай боққанимни ўзингиз яхши биласиз, — деди ҳазрати Яъқуб.  — Мен келмасимдан олдин бунча молингиз йўқ эди. Мен келиб, Оллоҳ сизни мана қанча баракалади. Пода-сурувларингиз кўпайиб кетди. Энди мен ҳам ўз рўзғорим ҳақида ўйлашим керак-ку, ахир!
            </p>

            <p>
              —Қанча тўласам рози бўласан?
            </p>

            <p>
              —Менга пулингиз керак эмас. Майли, бундан кейин ҳам қўйларингизни боқаман. Фақат бир шартим бор: бугун бориб сурувлардан ола, холдор ва қора қўйларни, ола ва холдор эчкиларни алоҳида ажратамиз. Ана шулар менинг ҳаққим бўлади. Кейин агар менинг сурувларим ичидан биронта оқ қўй топсангиз ёки ола ё холдор бўлмаган эчки топсангиз, уни мендан ўғирладинг, дейишга ҳаққингиз бор.
            </p>
            <p></p>
          </div>
          <div className='main_text'>
            <div className='speech-bubble'

              style={{ display: visibleBubbles[1] ? 'block' : 'none' }}
              onClick={() => handleSupClick(1)}


            >
              <p>
                <sup>1</sup>   Ўша пайтда ола ва холдор эч­ки­лар, ола, холдор ва қора қўйлар су­рувларда кам бўл­ган. Шу са­баб­дан Яъқуб (а.с.) нинг так­лифи Лобон тарафидан жуда яхши қабул қи­линди.
              </p>
            </div>
            <p onClick={() => handleSupClick(1)}>
              —Яхши, сен айтгандай бўлсин, — деди Лобон<sup>1</sup>  ва ўша куннинг ўзида ола ва холдор эчкиларни, ола, холдор ва қора қўйларни алоҳида ажратиб, ўғилларига: «Яъқубнинг қўйларини сизлар боқасизлар.  Сурувларнинг ораси уч кунлик йўл бўлсин!» деб тайинлади.
            </p>
            <p>
              Яъқуб (а.с.) эса тоғасининг қўйларини боқишни давом эттирдилар.   Қўй боқиб юрганларида бундай бир иш қилдилар: чинор, бодом ва терак шохларини кесиб, пўстини йўл-йўл қилиб шилдилар.  Кейин пўсти шилинган шохларни ҳайвонлар сув ичадиган охурларнинг ёнига қўйдилар.
            </p>
            <p>
              Ҳайвонлар сув ичишга келганда, шохларнинг олдида бир-бирини қочирар, кейин туққанда қўзилари ола ва холдор бўлиб туғилар эди.
            </p>
            <p>
              Яъқуб (а.с.) уларни алоҳида ажратар, қолган ҳайвонларнинг бошини эса уларга қаратиб қочирар эди.  Қачон сурувда яхши катта қўйлар қочадиган бўлса, у киши шохларни охур ёнига, қўйларнинг рўпарасига қўяр,  заиф қўйлар қочаётганда эса, қўймас эдилар.
              Натижада Лобонга тегишли бўлган ҳайвонлар заиф, Яъқуб (а.с.) га тегишли бўлган ҳайвонлар эса бақувват бўлиб туғилар эди. Шундай қилиб, ҳазрати Яъқуб жуда бойиб кетдилар. Кўп сурувлар, туяю эшаклар, қулу чўриларга эга бўлдилар.
            </p>
          </div>

          <div className='paragraph_heading'>
            <p>
              ҚОЧИШ
            </p>
          </div>


          <div className='main_text'>
            <p>
              Бир куни ҳазрати Яъқуб қайноғаларининг: «Яъқуб отамизнинг ҳамма нарсасини ўзиники қилиб олди. Отамизнинг ҳисобидан бойиб кетди», деган сўзларини эшитиб қолдилар. Лобоннинг муносабати ҳам илгаригидай эмас эди.
            </p>

            <p>
              Шу кунлардан бирида Оллоҳ таолодан: «Эй Яъқуб, сен энди ота-бобонг юртига, қариндошларинг олдига қайт. Мен сен билан бирга бўламан», деган пайғом келди.
              Шундан сўнг Яъқуб (а.с.) Роҳийла билан Лайёга даштга, қўйларнинг бошига келинглар, деб хабар юборади.
            </p>

            <p>
              Хотинлари келгач: «Отангизнинг авзойи бошқача, менга муносабати аввалгидай эмас, — деди уларга. — Лекин отамнинг Худоси доим мен билан бирга. Биласизлар, мен шу вақтгача отангизга сидқидилдан хизмат қилиб келдим. Бироқ у мени алдаяпти. Бугунгача ўн марта хизмат ҳаққимни ўзгартирди. Хайрият, менга ёмонлик қилишига Худо йўл бермади.
              “Хизмат ҳаққинг холдор ҳайвонлар бўлсин”, деганида, жониворларнинг ҳаммаси холдор туғишни бошлади. “Ола ҳайвонлар хизмат ҳаққинг бўлсин” деса, ҳаммаси ола туғди.
              Худо шу йўл билан отангизнинг молларини менга олиб берди.
            </p>
            <p>
              Ҳайвонлар қочадиган мавсумда бир туш кўрдим. Тушимда қарасам, қочираётган така ва қўчқорлар ола ва холдор экан.  Шунда Худонинг фариштаси менга: “Яъқуб”, деди. Мен: “Лаббай”, дедим.  У: “Қара, кўряпсанми, қочираётган такаларнинг ҳаммаси ола ё холдор. Чунки Оллоҳ Лобоннинг сенга қилган ҳамма ёмонликларини кўриб турибди.  Байтилда рўй берган воқеа эсингдами? Сен у ерда ёдгорлик тоши қўйиб, устидан ёғ қуйган эдинг. Оллоҳга бағишлаб назр атаган эдинг. Энди бу ердан кет. Туғилган юртингга қайт”, деди. Сизлар нима дейсизлар?»
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'

              style={{ display: visibleBubbles[2] ? 'block' : 'none' }}
              onClick={() => handleSupClick(2)}


            >
              <p>
                <sup>2</sup>  Яъқуб (а.с.) қалин ҳаққи учун ўн тўрт йил мобайнида Ло­бон­нинг қўй­ла­ри­ни боққан эди­лар.
              </p>
            </div>
            <p onClick={() => handleSupClick(2)}>
              Роҳийла билан Лайё (р.а.) Яъқуб (а.с.) га бундай деб жавоб бердилар: «Биз отамизга худди ўгайдек бўлиб қолганмиз. Энди ундан бирор нарса мерос оламиз, деб умид қилмасак ҳам бўлади. У бизга бегонага муомала қилгандай муомала қиляпти: ана, бизни сотиб, биз учун олган қалинни ҳам ўзига харж қилди.<sup>2</sup>  Шунинг учун Худо ундан олиб бизга берган молларнинг ҳаммаси бизники, фарзандларимизники. Худо сизга нимаики деган бўлса, қилинг!» дейишди.
            </p>
            <p>
              Шундан сўнг ҳазрати Яъқуб хотин-бола-чақаларини туяларга миндириб, қўй-қўзиларини олдига солиб, йиққан мол-давлатини, Байнул-наҳрайнда қўлга киритган бор-йўғини олиб, отаси Исҳоқ яшаётган Канъон юртига қараб йўлга тушдилар.  (Роҳийла Биби отасининг тимсолларини ҳам ўзи билан олиб кетади.) Бу пайтда Лобон қўйларининг юнгини қирқиш учун кетган эди.
            </p>
            <p>
              Шундай қилиб, Яъқуб (а.с.), Лобонга билдирмай,
              ўзининг ҳамма нарсасини олиб, Фирот дарёсидан ўтиб, Жилъод тоғларига қараб жўнадилар.
            </p>
          </div>

          <div className='paragraph_heading'>
            <p>
              ЛОБОННИНГ ҚУВЛАШИ ВА ОЛЛОҲНИНГ ОРАГА ТУШИШИ
            </p>
          </div>

          <div className='main_text'>
            <p>
              Уч кунгача Лобон уларнинг қочганини эшитгани йўқ.
              Эшитиши билан, қариндошларини йиғиб, уларнинг орқасидан қувди. Етти кундан кейин Жилъод тоғларига, Яъқуб (а.с.) чодир қурган жойга яқинлашдилар. Ўша куни тунда Оллоҳ таоло Лобонни тушида огоҳлантириб: «Эҳтиёт бўл, Яъқубни таҳдид қила кўрма!» деб айтади.
            </p>
            <p>
              Эртасига Лобон   Яъқуб (а.с.) нинг олдига келди: «Бу нима қилганинг? Менга айтмай, қизларимни асирга ўхшатиб олиб кетибсан!?
              Нега яширинча қочдинг? Мана мен кетяпман, деганингда, ўзим сени куй-қўшиқлар билан кузатар эдим!
              Ҳеч бўлмаса, невараларимни ўпиб, хайрлашишга ҳам қўймадингми?! Аҳмоқона иш қилдинг!
              Сизларга ёмонлик қилиш қўлимдан келади. Лекин кечаси отангнинг Худоси тушимда аён бериб, эҳтиёт бўл, Яъқубни таҳдид қила кўрма, деди. Хўп майли, отангнинг уйини соғинибсан, лекин нега менинг тимсолларимни ўғирладинг?»
            </p>
            <p>
              «Қўрқдим, — деб жавоб бердилар ҳазрати Яъқуб. — Қизларингизни мендан тортиб оласиз, деб ўйладим. Бироқ тимсолларингизга келсак, уларни ким олган бўлса, тирик қолмасин! Ана, ахтариб кўринг, қариндошлар гувоҳ! Бирон нарсангизни топсангиз, кейин гапиринг». Тимсолларни Роҳийла Биби олганини у киши билмас эдилар.
            </p>
            <p>
              Лобон тимсолларини қидира бошлади. Аввал Яъқуб (а.с.) нинг, кейин Лайё, Билҳа ва Зилфанинг чодирларини қараб чиқди. Ҳеч нарса топмагач Роҳийланинг чодирига кирди.  Бу пайтда Роҳийла Биби, тимсолларни туя эгарининг остига қўйиб, устида ўтирган эди. Лобон ҳамма ёқни синчиклаб қарай бошлади. «Отажон, турмаганимга кечиринг, — деди Роҳийла (р.а.). — Менинг ойлик касалим келган, шунинг учун туролмадим».

            </p>
            <p>
              Лобон ҳеч нарса топмай, ташқарига чиқди. Ҳазрати Яъқубнинг сабр косаси тўлган эди. «Менинг айбим нима? Нима гуноҳ қилдим, мени таъқиб қилиб юрибсиз? — деди у киши асабийлашиб Лобонга.  — Ана, ҳамма ёқни кўрдингиз, ўзингизга тегишли бирон нарса топдингизми? Топган бўлсангиз, олиб чиқинг мана қариндошлар олдига, кўрсин!

            </p>
            <p>
              Мен йигирма йил сизникида ишладим. Йигирма йил ҳайвонларингиздан биронтаси ҳам бола ташлагани йўқ. Сурувингиздан бир дона ҳам қўй сўйиб емадим.
              Бирор қўйни бўри тортиб кетса товонини ўзим тўлардим. Кундузи бўладими, кечасими, сурувдан йўқолган ҳар бир ҳайвонни менга тўлатгансиз.
              Кундузлари иссиқдан, кечалари совуқдан қийналдим, кўзларим уйқу деган нарсани билмади.
              Йигирма йил умрим сизнинг уйингизда шундай ўтди! Икки қизингиз учун ўн тўрт йил хизмат қилдим. Кейин яна олти йил сурувларингизни боқдим. Олти йил ичида ҳаққимни ўн марта ўзгартирдингиз.  Бобом Иброҳимнинг, отам Исҳоқнинг Худоси ўртага тушмаганда ҳозир ҳам мени қуппа-қуруқ жўнатган бўлардингиз. Худо тортган азобларимни, чеккан заҳматларимни кўриб кеча тунда сизни огоҳлантирган».
            </p>
            <p>
              Лобон бундай деб жавоб берди: «Сенинг хотинларинг — менинг қизларим, сенинг болаларинг — менинг болаларим, сурувларинг ҳам менинг сурувларим. Буларнинг ҳаммаси аслида меники. Шундай бўлса ҳам, бугун мен ўз қизларимга, невараларимга ҳукм ўтказолмай қолдим.
              Кел майли, энди бир-биримиз билан келишим тузайлик. Ана шу келишим орамизда гувоҳ бўлади», деди.
            </p>
          </div>

          <div className='paragraph_heading'>
            <p>
              ЯЪҚУБ (А.С.) БИЛАН ЛОБОН ЎРТАСИДАГИ КЕЛИШИМ
            </p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'

              style={{ display: visibleBubbles[3] ? 'block' : 'none' }}
              onClick={() => handleSupClick(3)}


            >
              <p>
                <sup>3</sup>   “Мисфа” деган сўз “кузатиш жойи” деган маънони англатади. Чунки Лобон: «Модомики энди алоҳида-алоҳида яшар эканмиз, Оллоҳнинг Ўзи биз бу келишувга қандай амал қилаётганимизни кузатиб турсин», деган эди.
              </p>
            </div>
            <p onClick={() => handleSupClick(3)}>
              Ҳазрати Яъқуб рози бўлиб, бир тошни белги сифатида тик қилиб турғиздилар.
              Кейин қариндошларига: «Тош йиғинглар», дедилар. Тошларни келтириб бир ерга тўпладилар.
              Лобон тош тепага Яжар-Шаҳадуто, Яъқуб (а.с.) бўлса Жалъид деб от қўйди. (Бу иккала сўз “шаҳодат тош тепаси” деган маънони билдиради. “Яжар-Шаҳадуто” — арамий тилида, “Жалъид” сўзи иброний тилида. Бу тепа яна “Мисфа”<sup>3</sup>  деб ҳам аталган.)
            </p>
            <p>
              «Агар қизларимга ёмон муомала қилсанг ёки уларнинг устига бошқа хотинлар олсанг, мен кўрмасам ҳам Оллоҳ кўради, эсингда бўлсин, — деди Лобон. — Мана шу тепа билан тош устун, орамиздаги келишимга шоҳид бўлади.
              Ҳеч қайсимиз мана шу чизиқдан ўтиб, бир-биримизга ёмонлик қилмаймиз.
              Оталаримиз Иброҳим ва Ноҳурнинг Худоси орамизда ҳакамлик қилсин». Кейин Яъқуб (а.с.) ота-бобосининг илоҳини тилга олиб қасам ичдилар.
            </p>
            <p>
              Сўнгра ўша тепалик олдида қурбонлик сўйиб ҳаммани таомга таклиф қилдилар. Зиёфатни еб, кечани ўша ерда ўтказдилар. <span className="vitalic">(Бу зиёфат аҳд-келишим муносабати билан ейилаётган эди. Ўша пайтларда томонлар ўзаро бир келишимга келса, таом истеъмол қилишган.) </span>

            </p>
            <p>
              Лобон эртасига вақтли туриб, қизлари ва невараларини ўпиб, уларни дуо қилди ва уйига қайтиб кетди.
            </p>
            <p></p>
          </div>

          <article className='nav_arrows'>
            <div className='flex_it space_between'>
              <div style={{ marginLeft: '0px' }} onClick={() => navigate(-1)}>
                <div>
                  <span className='left_arrow_span'>&#8592;</span>
                </div>
                <div className='indicator'>
                  <span className=' bottom_arrows_for_qisas' > орқага  </span>
                </div>
              </div>

              <div className='central_div'>
                <div>
                  <p>Канъонга қайтиш</p>
                </div>
              </div>


              <div style={{ marginRight: '0px' }} onClick={() => navigate('/qisas-uzbek/fight-with-angel')}>
                <div className='indicator'>
                  <span className=' bottom_arrows_for_qisas' >  Фаришта билан олишув</span>
                </div>
                <div>
                  <span className='right_arrow_span' >&#8594;</span>
                </div>
              </div>
            </div>
          </article>
        
        </main>
        <aside> </aside>
      </div>
    </div >
  )
}

export default EXAMPLE
