import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './stories.css'


const TheFall = () => {

  const navigate = useNavigate();

  // const [search, setSearch] = useState('')
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [visibleBubbles, setVisibleBubbles] = useState({});

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    const initialVisibility = {};
    const numberOfCases = 4;
    for (let i = 1; i <= numberOfCases; i++) {
      initialVisibility[i] = window.innerWidth > 970;
      //     console.log(initialVisibility[i])
    }
    setVisibleBubbles(initialVisibility);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleSupClick = (index) => {
    setVisibleBubbles(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  return (

    <div className="minister-home stories">
      <div className="separate_into_two_columns">
        <main className="sep_part1">
          <div className='chapter_heading2'>
            <p>  Жаннат боғидан </p>
            <p>ҳайдалиш</p>
          </div>

          <div className='decor_line'>
            <p> &#8213;&#8213;  &#10044; &#8213;  &#10044;  &#8213;  &#10044;  &#8213;&#8213;   </p>
          </div>

          <div className='main_text'>
            <p className='boldItalic'>
              «Айтдик: «Эй Одам, сиз жуфтингиз билан жаннатни маскан тутинг ва ундаги нарсалардан хоҳлаганча енг.
              Фақат мана бу дарахтга   яқинлашманглар, у ҳолда золимлардан бўлиб қоласиз» <span className='vitalic'> (Қуръон, Бақара сураси, 35).</span>
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[1] ? 'block' : 'none' }}
              onClick={() => handleSupClick(1)}
            >
              <p>
                <sup>1</sup>   Шайтоннинг нияти Момо Ҳаввонинг диққат-эътиборини битта нарсага — яхши ва ёмонни билиш дарахтига жалб этиш эди.
              </p>
            </div>
            <p onClick={() => handleSupClick(1)} >
              Кунлардан бир кун шайтон илон қиёфасида Момо Ҳавво (р.а.) нинг олдига келиб: «Ростдан ҳам Оллоҳ боғдаги ҳеч қайси дарахтнинг мевасидан еманглар, деб айтганми?» деб сўради. <sup>1</sup>
            </p>

            <p>
              «Биз боғдаги ҳамма дарахтларнинг мевасидан ея оламиз, — деб жавоб берадилар Ҳавво онамиз. — Оллоҳ фақат бир дарахтни бизга ҳаром қилган. Унинг мевасини еманглар, қўл ҳам тегдирманглар, бўлмаса ўласизлар, деган».
            </p>

            <p>
              «Йўқ, ўлмайсизлар, — деди шайтон. — Худо атайлаб сизларга шундай деган.
              Чунки бу дарахтнинг мевасидан есангизлар, кўзларингиз очилади. Кейин Худога ўхшайдиган бўласизлар, яхши-ёмонни фарқлай оласизлар».
            </p>

            <p>
              Момо Ҳавво (р.а.) мевадан кўз узолмай қолди. Мева жуда чиройли, жуда ширин кўринар эди. Агар есам, билимли бўлар эканман, деб ... мевадан узиб еди. Кейин ёнида турган завжига берди, у ҳам еди.
            </p>

            <p className='boldItalic spec_font'  >
              «Одам <span className='vitalic'>(алайҳис-салом) </span> Парвардигорга осий бўлиб, йўлдан озди» <span className='vitalic'>(Тоҳо сураси, 121-нчи оят).</span>
            </p>


            <p className='vitalic'>
              Шу билан шайтон Худони ёлғончи қилмоқчи бўлди. “У атайлаб сизларга шундай деган”, деди у. “Э воҳ, — деб ўйлади Ҳавво онамиз. — Демак Оллоҳ бизнинг баъзи нарсаларни билишимизни истамас экан-да!
            </p>

            <p className='vitalic'>
              Шайтон нима деб васваса қилди: “Агар бу дарахтнинг мевасидан есангизлар, Худога ўхшайдиган бўласизлар. Қачонгача жаҳолатда яшайсиз? Нима яхшию нима ёмонлигини билишни истамайсизми?” Шайтоннинг сўзларидаги маъно шу эди.
            </p>

            <p className='vitalic'>
              Момо Ҳавво нима деб ўйлади? “Агар Худо биздан бу нарсани яшираётган бўлса, демак бизни яхши кўрмас экан-да!” деб ўйлаган бўлса керак. Унда нима қилиш керак? Худо бермаётган бўлса нима қилиш керак? Ўз кучимиз билан эришамизми? “Ол, олавер, қўрқма, — дерди васваса. — Худога ишонсанг юраверасан!”
            </p>
            <p className='vitalic'>
              Шундай қилиб, шу пайтгача Худо билан маслаҳатлашиб, Худога суяниб, Худога ишониб яшаётган биринчи ота-онамиз энди мустақил қадам ташлашга, Худони четлаган ҳолда яшашга қарор қилишди.
            </p>
          </div>

          <div className='paragraph_heading'>
            <p>
              НАТИЖАДА...
            </p>
          </div>

          <div className='main_text'>
            <p className='boldItalic'>
              «<span className='vitalic'>(Шайтон)</span> алдов билан уларни <span className='vitalic'>(тубан ҳолатга)</span> тушириб қўйди. Ўша дарахтдан тотиб кўришган эдилар, авратлари очилиб қолди ва ўзларини жаннат япроқлари билан тўса бошладилар» <span className='vitalic'>(Қуръон, Аъроф сураси, 22).</span>
            </p>
            <p>Шу заҳоти иккаласининг ҳам кўзи очилиб, яланғоч эканликларини билиб қолдилар ва анжир баргларидан ўзларига сатр ясадилар.
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[2] ? 'block' : 'none' }}
              onClick={() => handleSupClick(2)}
            >
              <p>
                <sup>2</sup> Гуноҳ инсонда айбдорлик ва уят ҳиссини туғдиради. Одам (а.с.) нинг уялгани ва қочгани Аҳмад бин Ҳанбал (“Аз-зуҳд” китобида) ҳамда Табарий, Салабий, Ибн Асирлар томонидан баён қилинган.
              </p>
            </div>
            <p onClick={() => handleSupClick(2)}>
              Кечки шаббода эсаётганда Оллоҳнинг жалоли боққа нузул қилди. Одам Ато ҳам, Момо Ҳаво ҳам <span className='vitalic'>даҳшатга тушдилар.</span> Қочиб, дарахтларнинг орасига беркиндилар.<sup>2</sup>
            </p>

            <p> «Қаердасан? — деб чақирди Оллоҳ.</p>
            <p>«Келаётганингни эшитиб қўрқдим, — деди Одам (а.с.), — чунки яланғочман».</p>
            <p>«Яланғочсан деб ким айтди сенга? Мен ема, деган мевадан едингми?»</p>
            <p> «Сен берган хотин менга бу мевани берди, — деди Одам алайҳис-салом. — Кейин мен едим».</p>
            <p>
              Шунда Худо Момо Ҳаввога: «Нима қилиб қўйдинг?» деди. «Илон мени алдади, кейин мен едим», деди у.
            </p>
            <p>
              Худо илонга: «Бу ишинг учун ҳайвонлар ичида лаънатландинг, — деди. — Энди бир умр қорнинг билан судралиб юрасан. Емишинг тупроқ бўлади.
              Мен сен билан хотинни, сенинг наслинг билан унинг наслини бир-бирингизга душман қиламан. Сен унинг тўпиғини чақасан, у эса бошингни эзади».
            </p>

            <p>
              Кейин Ҳавво онамизга: «Энди сен қаттиқ қийналиб, азоб билан бола туғасан, — деди. — Эрингга қараб талпинасан, у эса устингдан ҳокимлик қилади».
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[3] ? 'block' : 'none' }}
              onClick={() => handleSupClick(3)}
            >
              <p>
                <sup>3</sup> Гуноҳ туфайли ер юзига лаънат кириб келди.
              </p>
            </div>
            <p onClick={() => handleSupClick(3)}>
              Одам (а.с.) га эса: «Хотининг гапига кириб, Мен ҳаром қилган мевани еганинг учун ер лаънатланади.<sup>3</sup>  Энди бутун умр ерда заҳмат чекиб нон топасан,  ер тикан ўстириб сени қийнайди, — деди. — То тупроққа қайтгунча, пешана теринг билан нон топасан. Оқибат тупроққа қайтасан. Чунки тупроқдан олингансан, яна қайтиб тупроққа айланасан».
            </p>
          </div>

          <div className='paragraph_heading'>
            <p>  ҲАЙДАЛИШ</p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[4] ? 'block' : 'none' }}
              onClick={() => handleSupClick(4)}
            >
              <p>
                <sup>4</sup> Одам алайҳис-салом жаннатдан жума куни чиқарилган экан (Термизий, Ибн Можа, Муслим) тавбаси ҳам жума куни қабул қилинган экан (Абу Довуд).
              </p> </div>
            <p className='boldItalic' onClick={() => handleSupClick(4)}>
              «Бас, уларни шайтон йўлдан оздириб, масканларидан чиқарди<sup>4</sup>  ва айтдик: «Тушингиз! <span className='vitalic'>(Сизлар)</span> бир-бирингизга душмансиз. Энди маълум вақтгача ерда маскан тутиб яшайсиз» <span className='vitalic'>(Бақара сураси 36).</span>
            </p>

            <p>
              Кейин Оллоҳ таоло Одам Ато ва Момо Ҳавво учун теридан кийим қилди ва:
              «Одамзот вақтидан олдин Биз каби яхши-ёмонни билишни истаб қолди. Энди ҳаёт дарахтидан еб абадий яшашига йўл бермайлик. Бориб, тупроққа ишлов берсин», деб уларни боғдан ҳайдаб чиқарди.  <span className='vitalic'> Ўз ҳузурида яшашдан маҳрум этди.</span>  Боғнинг эшигига бўлса каруб деган махлуқларни қўриқчи қилиб тайинлади. Ҳар томонга ҳаракат қиладиган оловли қилич йўлни қўриқлаб турарди.
            </p>

            <p className='vitalic'>
              Гуноҳ туфайли одамлар Оллоҳнинг ҳузурида яшашдан маҳрум бўлишди. Фақат Оллоҳ билан бирга яшашни биз «ҳақиқий ҳаёт, бахтли ҳаёт» деб аташимиз мумкин. Унда Оллоҳсиз яшашни нима деб атаймиз? Ўлим дебми?

            </p>

            <p className='vitalic'>
              Тўғри. Ўлим сўзи аслида айрилиқни англатади. Ўлган одам қариндош-уруғларидан, бола-чақасидан айрилади. Лекин энг ёмон ўлим — Худодан, Худонинг ҳузуридан айрилишдир. Худо «ундан еган кунингоқ ўлимга юз тутасан», деб бекорга айтмаган эди.
            </p>
          </div>

          <div className='paragraph_heading'>
            <p> НИМА БЎЛДИ ЎЗИ?</p>
          </div>

          <div className='main_text'>
            <p className='vitalic'>
              Хўш, шунга нима бўлибди, деб ўйлашингиз мумкин. Биласизми, агар гуноҳ бўлмаганда биз Худонинг ҳузуридан ҳайдалмаган бўлардик. Энди ўртага девор тушди.
            </p>
            <p className='vitalic'>
              Гуноҳ бўлмаганда касаллик ёки ўлим ҳам йўқ эди. Худо Одам Атони касал бўлсин, ёки фалон йилдан кейин вафот этсин, деб яратган эмас эди.
            </p>

            <p className='vitalic'>
              Гуноҳ бўлмаганда одамлар ўртасида хафачилик, гина, тушунмовчилик, ғазаб ёки нафрат каби нарсалар ҳам бўлмасди. Оллоҳ инсонларни бир-бирига меҳр-оқибатли бўлсин, бир-бирлари билан яхши покиза муносабат қурсин, деб яратган.
            </p>

            <p className='vitalic'>
              Атрофингизга қаранг. Кўриб турган ёмон нарсаларнинг биронтаси ҳам бошида йўқ эди. Худо инсонни гўзал ҳаёт учун, ҳақиқий ҳаёт учун яратган эди. Гуноҳ туфайли дунё ўзгариб кетди.
            </p>
            <p className='vitalic'>
              Худо бизни яна, ер юзини обод қилишимиз учун ҳам яратган эди. Ҳозир эса инсонлар бунинг тескарисини қиляпти: ер заҳарланган, сув заҳарланган, ҳаво заҳарланган... Одамлар энди Худо хоҳлаганидек ҳаёт кечираётганлари йўқ.
            </p>

            <p className='vitalic'>
              Энг ёмони, одамзот Худонинг ҳузуридан маҳрум бўлди. Биз ҳайдалдик — Худонинг ҳузуридан ҳайдалдик.
            </p>

            <p className='boldItalic'>
              «Кейин Одам ўз Раббидан бир неча сўзлар қабул қилиб олганидан сўнг <span className='vitalic'>(Оллоҳ)</span> унинг тавбасини қабул қилди. Албатта У тавбаларни қабул қилгувчи меҳрибон Зотдир. «У жойдан ҳаммангиз тушингиз», дедик. «Бас, сизларга Мен тарафдан Ҳидоят келганида <span className='vitalic'> (унинг орқасидан эргашинглар).</span> Менинг Ҳидоятимга эргашганлар учун ҳеч қандай хавфу хатар йўқ ва улар ғамгин бўлмайдилар» <span className='vitalic'>(Бақара сураси 37-38).</span>
            </p>

            <p className='vitalic'>
              Оллоҳ таоло юборадиган Ҳидоятга эргашиш ҳақида ҳадис ҳам бор. Абу Ҳурайра (р.а.) ривоят қилишича пайғамбаримиз Муҳаммад (с.а.в.) деганлар: «Мусо муножот пайтида Раббиларидан етти нарсани сўрадилар. Уларнинг олтитаси ўзлари учун эди, еттинчисини эса Мусо яхши кўрмас эдилар.
            </p>

            <p className='vitalic'>
              Дедиларки: «Раббим, қайси банданг тақволироқ?»
              <br />
              Оллоҳ дедики: «Мени доим эслаб юрадиган ва унутмайдиган бандам».
            </p>

            <p className='vitalic'>
              Дедиларки: «Қайси банданг энг тўғри йўлда?»

              <br />
              Оллоҳ дедики: «Ҳидоятга эргашгани».
            </p>

            <p className='vitalic'>
              Дедиларки: «Қайси бандангнинг ҳукми ўткирроқ?
              <br />
              Дедики: «Одамларга худди ўзига ҳукм қилаётгандек ҳукм қилганнинг».
            </p>

            <p className='vitalic'>
              Дедиларки: «Қайси банданг билгувчироқ?»
              <br />
              Дедики: «Илмга тўймайдиган ва одамларнинг илмини ўз илми билан жамлайдигани».
            </p>

            <p className='vitalic'>
              Дедиларки: «Қайси банданг азизроқ?»
              <br />
              Дедики: «Қодир бўла туриб кечиргани».
            </p>

            <p className='vitalic'>
              Дедиларки: «Қайси банданг бойроқ?»
              <br />
              Дедики: «Берилганига шукур қилгани».
            </p>

            <p className='vitalic'>
              Дедиларки: «Қайси банданг фақирроқ?»
              <br />
              Дедики: «Нуқсон соҳиби».
            </p>
            <p></p>
          </div>




          <article className='nav_arrows'>
            <div className='flex_it space_between'>
              <div style={{ marginLeft: '0px' }} onClick={() => navigate('/qisas-uzbek/creation-of-world')}>
                <div>
                  <span className='left_arrow_span'>&#8592;</span>
                </div>

                <div className='indicator'>
                  <span className=' bottom_arrows_for_qisas' >  Яратилиш </span>
                </div>
              </div>

              <div className='central_div'>
                <div>
                  <p>Жаннатдан ҳайдалиш</p>
                </div>
              </div>

              <div style={{ marginRight: '0px' }} onClick={() => navigate('/qisas-uzbek/kain-abel')}>
                <div className='indicator'>
                  <span className=' bottom_arrows_for_qisas' >  Қобил ва Ҳобил</span>
                </div>
                <div>
                  <span className='right_arrow_span' >&#8594;</span>
                </div>
              </div>
            </div>
          </article>
       
        </main>
        <aside> </aside>
      </div>
    </div >
  )
}

export default TheFall
