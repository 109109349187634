import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './stories.css'


const EXAMPLE = () => {

  const navigate = useNavigate();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [visibleBubbles, setVisibleBubbles] = useState({});

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    const initialVisibility = {};
    const numberOfCases = 14;

    for (let i = 1; i <= numberOfCases; i++) {
      initialVisibility[i] = window.innerWidth > 970;
      // console.log(initialVisibility[i])
    }
    setVisibleBubbles(initialVisibility);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleSupClick = (index) => {
    setVisibleBubbles(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };


  return (

    <div className="minister-home stories">
      <div className="separate_into_two_columns">
        <main className="sep_part1">
          <div className='chapter_heading2'>
            <p> Бани Исроилнинг 40 йил саҳро  </p>
            <p>кезгани</p>
          </div>

          <div className='decor_line'>
            <p>
              &#8213;&#8213;  &#10044; &#8213;  &#10044;  &#8213;  &#10044;  &#8213;&#8213;
            </p>
          </div>

          <div className='main_text'>
            <p className="vitalic">
              Ниҳоят Бани Исроил Қодашга, Канъон чегарасига келиб қароргоҳ қурди. Улар энди, ваъда қилинган юртга киришлари керак эди.
            </p>
            <p>
              Ўшанда халқ Мусо алайҳис-саломнинг олдларига келиб: «Аввал юртни текширайлик. Бир неча одам юборамиз, биз кирадиган шаҳарларни кўриб келсинлар», деб таклиф қилади.
            </p>
            <p>
              Кейин Оллоҳ таоло Мусо пайғамбарга: «Ўн икки қабиладан ўн икки одам, ваъда қилинган юртни кўриб келсинлар», деб ваҳий берди. Шундан сўнг расулуллоҳ ўн икки одамни Канъонга жўнатдилар. Уларнинг ҳар бири Исроил халқи оқсоқолларидан эди.
            </p>
            <p>
              Расулуллоҳ уларга: «Ҳамма нарсага диққат билан қаранглар: ери яхшими-ёмонми? Халқи кучлими-заифми? Шаҳарлари мустаҳкамми? Ерлари унумдорми ё камҳосилми? Дарахтларининг бор-йўқлигига эътибор беринглар. Иложи бўлса, меваларидан олиб келинг», деб тайинладилар.
            </p>
            <p>
              Ўн икки оқсоқол Канъон ўлкасига кириб, қирқ кун юртни томоша қилдилар. Қирқ кундан кейин қароргоҳга қайтиб келдилар. Ўзлари билан Канъоннинг меваларидан анор, анжир ва узум олиб келишди. Улар олиб келган узум боши шунақанги катта эди-ки, икки киши таёққа осиб, кўтариб келган эди.
            </p>
          </div>

          <div className='paragraph_heading'>
            <p> ЭЪТИҚОДНИНГ СИНАЛИШИ  </p>
          </div>

          <div className='main_text'>
            <p>
              «Ери жуда яхши, чинданам сут ва асал оқиб ётибди, деб айтишга арзигулик жой, — дейишди улар. — Бироқ халқи жуда кучли, шаҳарлари ҳам катта-катта, мустаҳкам экан. Уларга бизнинг кучимиз етмайди! Жанубида-чўлда амолиқлар, қирларда ҳитий, ябусий ва амўрийлар, денгиз ва дарё бўйларида канъонийлар яшар экан».
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[1] ? 'block' : 'none' }}
              onClick={() => handleSupClick(1)}
            >
              <p >
                <sup>1</sup> Қуръони каримдан оят: «Мусо айтдики..: «Эй қавмим, Оллоҳ сизга ёзиб қўйган муқаддас ерга киринг. Ортингизга қайтманг, бас, зиён кўргувчига айланасиз»... Улар: «Эй Мусо, унда зўравон қавм бор. Улар ундан чиқмагунларича биз ҳеч қачон кирмаймиз. Агар чиқсалар, биз кирувчидирмиз», дедилар. Улардан (Оллоҳдан) қўрқадиганлардан бўлган ва Оллоҳ уларга неъмат ато этган икки киши: «Уларнинг устига (шаҳар) дарвозасидан кириб боринглар. Агар унга кирсангиз, албатта, ғолиб бўласизлар. Мўмин бўлсангиз, Оллоҳга таваккул қилингиз», дедилар» (Моида сураси 21-23).
              </p>
            </div>
            <p onClick={() => handleSupClick(1)}>
              Бу гапларни эшитиб одамларни қўрқув босди.<sup>1</sup> Шунда Холиб — юборилган ўн икки оқсоқолдан бири — одамларнинг қўрқиб кетганини кўриб: «Руҳингларни туширманглар, биз уларни енгамиз, кучимиз етади», деди.
            </p>
            <p>
              Лекин бошқалар: «Йўқ, улар жуда кучли, бизнинг кучимиз етмайди, уларнинг бўйлари узун-узун. Паҳлавонлар наслидан бўлган иноқлар ҳам ўша ерда яшар экан, биз уларнинг олдида худди чигирткадаймиз», деб гап тарқатдилар.
            </p>
            <p>
              Буни эшитган халқ доду фарёд кўтарди. Баъзилар кечаси билан йиғлаб чиқди.    Кўплар Худога қарши нолишни бошладилар. “Оллоҳ бизни ёмон кўргани учун Мисрдан олиб чиққан экан, энди амўрийлар қўлига топшириб бизни ўлдирмоқчи! Ана, одамларининг бўйи узун экан, шаҳарлари баланд деворли, энди аниқ ўламиз”, деган гапларни айтдилар.
            </p>
          </div>

          <div className='paragraph_heading'>
            <p> ХАЛҚНИНГ ИСЁН КЎТАРГАНИ </p>
          </div>

          <div className='main_text'>
            <p className="boldItalic">
              «(Бани Исроил) айтди: «Ё Мусо, модомики, улар шу жойда экан, биз ҳеч қачон кира олмагаймиз. Бас, боргин, сен ўзинг ва Парвардигоринг улар билан уришаверинглар. Биз эса мана шу ерда ўтириб (кутамиз)»... . (Оллоҳ) деди: «Энди қирқ йил мобайнида бу ер улар учун ҳаром. Улар ерда (саҳроларда) адашиб-улоқиб юрурлар. Сен бу итоатсиз қавмдан хафа бўлма» (Моида сураси, 5:24,26).
            </p>
            <p>
              Кейин халқ ўзаро: «Мисрда ўлиб кетсак яхши бўларди! Энди хотин-бола-чақамиз душманга ўлжа бўладими?! Бундан кўра Мисрга қайтганимиз яхши эмасми? Орамиздан раҳбар тайинлаб, Мисрга қайтамиз», деган тараддудга тушди.
            </p>
            <p>
              Буни кўрган Мусо ва Ҳорун (а.с.) халқнинг кўз олдида тиз чўкиб Оллоҳга дуо қила бошладилар.
            </p>
            <p className="vitalic">
              Оллоҳ таоло бутун бир халқни Мисрдан — қулликда азоб чекиб турган жойидан олиб чиққан эди. Қул эмас, озод халқ бўласиз, Мен Ўзим сизларни ҳамма нарса билан таъминлайман, душманлардан сақлайман, деб ваъда берган эди. Ваъдаларининг исботи учун қатор-қатор мўъжизалар намойиш этди. Мана энди, “сут ва асал ўлкасига” кириш пайти келганда халқ яна иймонсизлик қилиб, Худонинг ёрдам беришига ишонмай, даҳшатга тушди. “Бундан кўра Мисрга қайтамиз”, деб Оллоҳдан кўра, фиръавнни ўзларига афзал билдилар.
            </p>
            <p>
              Кейин Мусо пайғамбар халққа: «Ваҳима қилманглар! Нега ваҳима қиласизлар? Оллоҳнинг қудратини Мисрда кўрмаган эдингизми? Оллоҳнинг Ўзи бизни қўллаб-қувватлайди. Оллоҳга ишонинглар!» дедилар.
            </p>
            <p>
              Ўн икки оқсоқолдан иккитаси — Юъшо билан Юфанно ўғли Холиб ҳам мусибатдан кийимларини йиртиб: «Биродарлар, илтимос, Оллоҳга қарши исён қилманглар! Худо жуда обод жойни бизга беряпти. Биз ўз кўзимиз билан кўриб келдик. Нега улардан қўрқасиз?! Ахир Худо биз билан бирга-ку! Худо ўша юртни бизга бераман, деб ваъда қилган!» деб халқнинг олдига чиқдилар.
            </p>
            <p>
              Бироқ одамлар издан чиқиб кетган эди. Ҳатто, “уларни тошбўрон қилиш керак”, деган даъватлар ҳам янгради. Шу аснода халқнинг кўз олдида бирданига Оллоҳ таолонинг шукуҳ-азамати намоён бўлди!
            </p>
          </div>

          <div className='paragraph_heading'>
            <p> ИСЁН УЧУН БЕРИЛГАН ЖАЗО </p>
          </div>

          <div className='main_text'>
            <p>
              «Қачон бу халқ Менга иймон қўяди?! — деди Оллоҳ таоло. — Қачонгача қаҳримни қўзғайди? Шунча мўъжизаларни кўриб ҳам иймони йўқ! Мен уларни қириб ташлаб, сенинг ўзингдан янги авлод яратаман!» деди Мусо пайғамбарга.
            </p>
            <p>
              «Ё Раббим! — деб ёлвордилар расулуллоҳ. — Сен бизни мўъжизалар билан Мисрдан олиб чиқдинг! Булутинг ҳар куни тепамизда турибди. Кундузи булут билан, кечаси олов билан бизга раҳнамолик қиляпсан! Агар халқни қириб ташласанг, бошқалар: “Оллоҳ берган ваъдасини бажара олмай уларни чўлга олиб бориб, ўлдирибди!” деб айтмайдими? Сен Ўзинг: “Мен сабрли, севгига бой, гуноҳларни кечиргувчи Худоман”, деб айтган эдинг-ку! Мана, Мисрдан чиққан кунимиздан то бугунгача бизни кечириб келяпсан. Баркамол севгинг ҳаққи, халқингнинг гуноҳидан ўт!» дедилар.
            </p>
            <p>
              «Сен учун уларнинг гуноҳидан ўтдим, — деди Оллоҳ таоло. — Аммо бу насл Менинг мўъжизаларимга гувоҳ бўла туриб, неча марта сабр-тоқатимни синади, неча марта амрларимдан бош тортди. Ваъда қилинган юртга улар кирмайди. Ёши йигирмадан ўтган биронта эркак сут ва асал ўлкасидан баҳраманд бўлмайди. “Болаларимиз душманга ўлжа бўлади”, дедингиз. Сизлар эмас, болаларингиз ваъда қилинган юртга эга бўладилар. Сизларнинг жасадингиз эса чўлда қолади.
            </p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[2] ? 'block' : 'none' }}
              onClick={() => handleSupClick(2)}
            >
              <p >
                <sup>2</sup>  Моида сурасининг 26-оятида бундай деб ёзилган: «У зот: «Албатта, бу ер уларга қирқ йилгача ҳаромдир. Улар ер юзида ҳайрон бўлиб юрарлар. Фосиқ қавмлар учун хафа бўлма», деди.
              </p>
            </div>
            <p onClick={() => handleSupClick(2)}>
              Орқага қайтинглар. То ҳаммангиз ўлиб кетмагунча, Мен сизларни саҳрода тентиратаман. Ваъда қилинган юртни қирқ кун айландингиз. Энди эса саҳрода қирқ йил овора бўласизлар».<sup>2</sup>
            </p>
            <p className='vitalic'>
              (Оллоҳ таоло ўшанда Юъшо ибн Нун ҳамда Юфанно ўғли Холибдан бошқа ҳеч кимни ваъда қилинган юртга киритмайман, деб огоҳлантиради. Қолган ўнта айғоқчи эса ўша куни ўлатга учраб, Оллоҳга омонатини топширадилар.)
            </p>
          </div>

          <div className='paragraph_heading'>
            <p>  ОДАМЛАР ЯНА ЎЗ БИЛГАНИДАН ҚОЛМАДИ  </p>
          </div>

          <div className='main_text'>
            <p>
              Халқ Оллоҳ таолонинг ҳукмини эшитиб, қилган ишларидан пушаймон бўлдилар. Эртасига эрталаб: «Биз гуноҳ қилдик. Энди Худо ваъда қилган юртга борамиз», деб айтдилар.
            </p>
            <p>
              «Нега яна Раббимиз амрига қарши чиқмоқчисиз?! — дедилар ҳазрати Мусо. — Борманглар, Худо сизлар билан бирга бормайди. Амолиқлар билан канъонийлар қўлида ўлиб кетасизлар».
            </p>
            <p>
              Лекин одамлардан бир гуруҳи барибир,   қўлларига қурол олиб, Канъонга юриш қилдилар. Амолиқлар билан канъонийлар эса пастга тушиб, уларни мағлубиятга учратди ва то Хурмо водийсигача қувлаб борди.
            </p>
          </div>

          <div className='paragraph_heading'>
            <p>  Сув учун жанжал </p>
          </div>

          <div className='main_text'>
            <p className="vitalic">
              Орадан анча вақт ўтди. Бани Исроил кўп йиллар мобайнида чўл кезди.
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[3] ? 'block' : 'none' }}
              onClick={() => handleSupClick(3)}
            >
              <p >
                <sup>3</sup> Мусо алайҳис-саломнинг опаси Марям (р.а.) шу ерда оламдан ўтган.
              </p>
            </div>
            <p onClick={() => handleSupClick(3)}>
              Кейин ниҳоят Оллоҳ (с.в.т.) уларни яна Син саҳросидаги Қадашга<sup>3</sup> олиб келди.
            </p>
            <p>
              Қодашда ичимлик суви йўқ эди. Одамлар Мусо ва Ҳорун (а.с.) нинг олдларига келиб дедилар: «Кошки Худо бизни ҳам ўлдирса эди! Нимага халқни бу ерга олиб келдинглар? Мол-ҳоли билан ўлиб кетсин, дебми?! Нега бизни Мисрдан олиб чиқиб, бу ерга олиб келдингиз? Бу ерда на буғдой бор, на анжир, на узум, на анор. Ичишга сув ҳам йўқ!»
            </p>
            <p>
              Мусо билан Ҳорун (а.с.) буни эшитиб Чодирнинг эшиги олдига бордилар-да, юзларини тупроққа қўйдилар.
            </p>
            <p>
              Шунда Оллоҳ таолонинг жалоли зоҳир бўлиб расулуллоҳга бундай ваҳий келди: «Мусо, асонгни ол, аканг Ҳорун билан жамоани йиғ. Кейин уларнинг олдида қояга “Сув бер!” деб амр қил».
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[4] ? 'block' : 'none' }}
              onClick={() => handleSupClick(4)}
            >
              <p >
                <sup>4</sup> Асо Чодирда сақланар эди.
              </p>
            </div>
            <p onClick={() => handleSupClick(4)}>
              Ҳазрати Мусо Чодирдан<sup>4</sup>  асони олдилар-да акаси Ҳорун билан бориб, Бани Исроилни қоя олдига тўпладилар.
            </p>
            <p>
              Кейин халққа: «Эй исёнчилар! Мана, ҳозир сизларга тошдан сув чиқарамиз!» деб қўлидаги асони икки марта тошга урдилар.
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[5] ? 'block' : 'none' }}
              onClick={() => handleSupClick(5)}
            >
              <p >
                <sup>5</sup> Аъроф сураси, 160-оятда, “ўн иккита булоқ отилиб чиқди”, деб ёзилган.
              </p>
            </div>
            <p onClick={() => handleSupClick(5)}>
              Тошдан шариллаб сув чиқа бошлади.<sup>5</sup> Ҳамма — одамлар ҳам, ҳайвонлар ҳам сувдан тўйиб-тўйиб ичдилар.
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <p>
              Ўшанда Худо Мусо ва Ҳорун (а.с.) га: «Нега Менинг айтганимни қилмадинглар? Менга тўла ишонмай, халқ олдида Менинг ҳурматимни жойига қўймадинглар», деб айтган экан.
            </p>
            <p className="vitalic">
              Кейин булоққа Мариба — яъни, жанжал деб от қўядилар. Чунки халқ сувсиз қолиб, жанжал кўтарган эди.
            </p>
            <p className="vitalic">
              Эсингизда бўлса, худди шунга ўхшаш воқеа аввал ҳам Ҳуриф тоғида юз берган ва ўшанда у жойга ҳам Мариба деб от берилган эди.
            </p>
          </div>

          <div className='paragraph_heading'>
            <p> МИС ИЛОННИНГ ЮҚОРИГА КЎТАРИЛИШИ </p>
          </div>

          <div className='main_text'>
            <p>
              Ҳур тоғидан чиқиб, Эдўм юртини айланиб ўтаётганларида халқ яна йўлда Оллоҳга ҳамда Мусо пайғамбарга қарши гаплар айтади: «Нега бизни Мисрдан олиб чиқдинглар? Чўлда очликдан-сувсизликдан ўлдириш учунми? Бу ерда емиш ҳам йўқ, сув ҳам йўқ. Мана бу бўлмағур нон аллақачон меъдамизга урган!»
            </p>
            <p>
              Шунда Оллоҳ таоло қароргоҳга заҳарли илонлар юборган экан. Илон чақиб одамлар ўла бошлайди.
            </p>
            <p>
              Охири халқ расулуллоҳнинг олдига келиб: «Биз гуноҳ қилдик, Худога ҳам, сизга ҳам қарши иш қилдик, бизни кечиринг! Оллоҳга айтинг, илонларни йўқотсин», деб ялинади.
            </p>
            <p>
              Мусо (а.с.) дуо қилган эдилар, Оллоҳ таоло у кишига: «Сен ўзингга мисдан илон ясаб, баланд жойга байроқ қилиб ос. Кимни илон чақса, байроққа қарасин, ўшанда тирик қолади», деб айтади. Расулуллоҳ Худонинг амрини бажо келтирадилар.
            </p>
            <p>
              Ўшанда илон чаққан одамлардан кимлар келиб, байроққа қараса, ўлмай тирик қолган эканлар.
            </p>
          </div>

          <div className='paragraph_heading'>
            <p> Ҳорун (а.с.) нинг вафоти  </p>
          </div>

          <div className='main_text'>
            <p>
              Эдўм юртининг Ҳур тоғига етганларида, Мисрдан чиққанларига қирқ йил бўлганда, бешинчи ойнинг биринчи куни, Ҳорун алайҳис-салом вафот этдилар.
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[6] ? 'block' : 'none' }}
              onClick={() => handleSupClick(6)}
            >
              <p >
                <sup>6</sup> Яъқубий, “Тарих”, 1-жилд, 41-бет; Масъудий. “Муруж-уз заҳаб”, 1-жилд, 50-бет.
              </p>
            </div>
            <p onClick={() => handleSupClick(6)}>
              Ўшанда у киши бир юз йигирма уч ёшга<sup>6</sup> чиққан эдилар.
            </p>
          </div>

          <div className='paragraph_heading'>
            <p>    ЮЪШО (А.С.) ХАЛҚҚА РАҲБАРЛИК ҚИЛАДИ  </p>
          </div>

          <div className='main_text'>

            <p >
              Ариҳога яқинлашганларида, Канъон чегарасида Худо Мусо пайғамбарга: «Тоққа чиқиб, Мен бермоқчи бўлган юртни томоша қил. Мен сени у ерга киритмайман», деди.
            </p>

            <p>
              «Ё Раббим, борлиқ жонлар Эгаси, тағин халқ чўпонсиз қўйлардек қолмасин, Сен ўзинг халқни бошқарадиган, халққа раҳбарлик қиладиган одам тайинла», дедилар Мусо пайғамбар.
            </p>
            <p></p>
          </div>



          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[7] ? 'block' : 'none' }}
              onClick={() => handleSupClick(7)}
            >
              <p >
                <sup>7</sup> Ибн-и Асир, “Комил”, 1-жилд, 200-бет.
              </p>
            </div>
            <p onClick={() => handleSupClick(7)}>
              «Халққа Юъшо раҳбарлик қилади, — деди Оллоҳ. — Мен уни Ўз Руҳим билан қувватладим. Сен уни бутун жамоа олдида раҳбар деб эълон қиласан».<sup>7</sup>
            </p>
            <p></p>
          </div>

          <article className='nav_arrows'>
            <div className='flex_it space_between'>
              <div style={{ marginLeft: '0px' }} onClick={() => navigate(-1)}>
                <div>
                  <span className='left_arrow_span'>&#8592;</span>
                </div>
                <div className='indicator'>
                  <span className=' bottom_arrows_for_qisas' > орқага қайтиш </span>
                </div>
              </div>

              <div className='central_div'>
                <div>
                  <p>40 йил саҳрода</p>
                </div>
              </div>

              <div style={{ marginRight: '0px' }} onClick={() => navigate('/qisas-uzbek/wasiya-of-Moses')}>
                <div className='indicator'>
                  <span className=' bottom_arrows_for_qisas' > Васият </span>
                </div>
                <div>
                  <span className='right_arrow_span' >&#8594;</span>
                </div>
              </div>
            </div>

          </article>
         
        </main >
        <aside> </aside>
      </div >
    </div >
  )
}

export default EXAMPLE
